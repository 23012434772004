import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import { Logo } from './Logo';
import { login, resetPwd, } from '../actions/user';
import { getErrorLogin, getSuccessPasswordReset, getErrorPasswordReset } from '../reducers/apiStatus';

class Login extends Component{
  constructor(props){
    super(props);
    this.state = {
      usr: '',
      pwd: '',
      email: '',
      resetView: false,
    };
  }

  login = (e) => {
    e.preventDefault();
      this.props.login({
          usr: this.state.usr,
          pwd: this.state.pwd,
      });
  };

  resetPwd = (e) => {
      e.preventDefault()
      this.props.resetPwd({
          email: this.state.email
      });
  };

  usrChange = (e) => {
    this.setState({usr: e.target.value});
  };

  pwdChange = (e) => {
    this.setState({pwd: e.target.value});
  };

  emailChange = (e) => {
    this.setState({email: e.target.value});
  };

  toggleView = (e) => {
    e.preventDefault();
    this.setState({
      resetView: !this.state.resetView
    });
  };

  render(){
    const { successReset, errorLogin, errorReset, errorResetCode, errorLoginCode, t } = this.props;

    return (
      <div className="login">
        <Card>
          <Logo />
            <CardBody className={[!this.state.resetView ? "on" : "off", errorLogin ? "error" : ""].join(' ')}>
              <Form onSubmit={this.login}>
                <FormGroup>
                  <Label for="usr">{t('login.user')}</Label>
                  <Input id="usr" onChange={this.usrChange} autoComplete="username" />
                </FormGroup>
                <FormGroup>
                  <Label for="current-password">{t('login.password')}</Label>
                  <Input id="current-password" type="password" onChange={this.pwdChange} autoComplete="current-password" />
                </FormGroup>
                <div className="buttons">
                  <Button
                    color="link"
                    block
                    type="button"
                    onClick={this.toggleView}
                  >
                    {t('login.forgotPwd')}
                  </Button>
                  <Button
                    color="secondary"
                    block
                    type="submit"
                  >
                    {t('login.connection')}
                  </Button>
                </div>
              </Form>
            </CardBody>

            <CardBody className={[this.state.resetView ? "on" : "off", errorReset ? "error" : ""].join(' ')}>
                    <Form onSubmit={this.resetPwd}>
                <p>
                  {t('login.resetPwdDesc')}
                </p>
                <FormGroup>
                  <Label for="email">{t('login.email')}</Label>
                  <Input id="email" type="email" onChange={this.emailChange}/>
                </FormGroup>
                <div className="buttons">
                  <Button
                    color="link"
                    block
                    type="button"
                    onClick={this.toggleView}
                  >
                    {t('login.back')}
                  </Button>
                  <Button
                    color="secondary"
                    block
                    type="submit"
                  >
                    {t('login.sendPwd')}
                  </Button>
                </div>
              </Form>
            </CardBody>
          { errorLogin &&
          <div className="error-label">
            {t('login.'+errorLoginCode)}
          </div>
          }
          { errorReset &&
          <div className="error-label">
            {t('login.'+errorResetCode)}
          </div>
          }
          {successReset &&
          <div className="success-label">
            {t('login.resetPwd')}
          </div>
          }
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorLogin: getErrorLogin(state),
  errorLoginCode: state.apiStatus.login && state.apiStatus.login,
  successReset: getSuccessPasswordReset(state),
  errorReset: getErrorPasswordReset(state),
  errorResetCode: state.apiStatus.passwordReset && state.apiStatus.passwordReset,
});

const mapDispatchToProps = (dispatch) => ({
  login(state){
    dispatch(login(state));
  },
  resetPwd(state){
    dispatch(resetPwd(state));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login));
