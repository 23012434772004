import { combineReducers } from 'redux';

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_VIEW_TOGGLE,
} from '../actions'

const login = () => {

	const error = (state = false, action) => {
		switch(action.type){
			case USER_LOGIN_SUCCESS:
				return false;
			case USER_LOGIN_FAILURE:
				return true;
			default:
				return state;
		}
	};

	const resetPwd = (state = false, action) => {
		switch(action.type){
			case USER_LOGIN_REQUEST:
				return false;
			default:
				return state;
		}
	}

	const resetPasswordStatus = (state = "", action) =>  {
		switch(action.type) {
			case RESET_PASSWORD_SUCCESS:
				return "success";
			case RESET_PASSWORD_FAILURE:
                return action.code || "errorResetPassword";
			case RESET_PASSWORD_REQUEST:
				return "";
			default:
				return state;
		}
	}

	const resetPasswordView = (state = false, action) => {
		switch(action.type){
			case RESET_PASSWORD_SUCCESS:
				return true;
			case RESET_PASSWORD_VIEW_TOGGLE:
				return false;
			default:
				return state;
		}
	};

	return combineReducers({
		error,
		resetPwd,
		resetPasswordStatus,
		resetPasswordView,
	});
}

export default login;

export const getErrorLogin = (state) => state.login.error;
export const getResetPasswordView = (state) => state.login.resetPasswordView;
export const getResetPwd = (state) => state.login.resetPwd;
