import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Card, CardImg, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import { Logo } from './Logo';
import { changePassword, resetPasswordView } from '../actions/user';
import { getResetPasswordView } from '../reducers/login';

class PasswordReset extends Component {
	constructor(props){
		super(props);

        const parsedUrl = new URL(window.location.href);
        const token = parsedUrl.searchParams.get('token');
        const email = parsedUrl.searchParams.get('email');

        // const token = window.location.href.split('token=')[1].split('&')[0];
        // const email = window.location.href.split('email=')[1];

        this.state = { newPassword: "", confirmPassword: "", token: token, email: email, errorNotValid: undefined,  errorNotMatch: undefined};

		this.newPasswordChange = this.newPasswordChange.bind(this);
		this.confirmPasswordChange = this.confirmPasswordChange.bind(this);
	}

	newPasswordChange(e) {
        this.setState({ newPassword: e.target.value });
	}

	confirmPasswordChange(e) {
        this.setState({ confirmPassword: e.target.value });
	}

	validatePasswordStrengh(password) {
		const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\!\@\#\$\%\:\;\µ\ù\.\?\,\[\]\-\_\|\/\^\&\*])(?=.{10,})/
		if (password.match(regex)) {
		  this.setState({ errorNotValid: false, errorNotMatch: false });
		  return true;
		} else {
			this.setState({ errorNotValid: true, errorNotMatch: false });
			return false;
		}
	}

	matchPasswords(newPass, confirmedPass) {
		if(this.validatePasswordStrengh(this.state.newPassword) === false)
            return true;

		if(newPass === confirmedPass) {
			this.setState({ errorNotMatch: false });
			return true;
		} else {
			this.setState({ errorNotMatch: true });
			return false;
		}
	}

    validate(e) {
        e.preventDefault();
        if (!this.state.newPassword || this.state.newPassword === "" || !this.state.confirmPassword || this.state.confirmPassword === "") {
            this.setState({ errorNotValid: true, errorNotMatch: false });
            return false;
        }

        const isValid = this.validatePasswordStrengh(this.state.newPassword) && this.matchPasswords(this.state.newPassword, this.state.confirmPassword);

		if(isValid) {
            this.props.resetPassword(this.state);
		}
	}

    render() {
        const { resetPwdState, t, resetViewState } = this.props;
		const { errorNotMatch, errorNotValid } = this.state;
		return (
			<div className="login">
				<Card>
        <Logo />
                    <CardBody className={!resetViewState ? "on" : "off"}>
                        <Form onSubmit={(e) => { this.validate (e)} }>
							<FormGroup>
								<Label for="usr">{t('login.newpassword')}</Label>
                                <Input id="new-password" type="password" onChange={this.newPasswordChange} onBlur={(e) => this.validatePasswordStrengh(e.target.value)} autoComplete="new-password" />
							</FormGroup>
							<FormGroup>
								<Label for="pwd">{t('login.confirmedpassword')}</Label>
                                <Input id="confirm-password" type="password" onChange={this.confirmPasswordChange} onBlur={(e) => this.matchPasswords(this.state.newPassword, e.target.value)} autoComplete="new-password" />
							</FormGroup>
							<div className="buttons">
								<Button
									color="link"
									block
									type="link"
									href="/"
								>
									{t('login.cancel')}
								</Button>
								<Button
									color="secondary"
									block
									type="submit"
								>
									{t('login.confirm')}
								</Button>
							</div>
						</Form>
                    </CardBody>

                    <CardBody className={resetViewState ? "on" : "off"}>
							<div className="buttons">
								<Button
									color="link"
									block
									type="link"
									onClick={this.props.resetPasswordView}
									href="/"
								>
									{t('login.signin')}
								</Button>
							</div>
                    </CardBody>

                    {
                        resetPwdState && resetPwdState.length > 0 && resetPwdState !== 'success' &&
						<div className="error-label">
							{t('login.'+resetPwdState)}
						</div>
					}
					{
						 errorNotValid &&
						 <div className="error-label">
							 {t('login.errorPasswordStrenth')}
						 </div>
					}
					{
						 errorNotMatch &&
						 <div className="error-label">
							 {t('login.errorPasswordNotMatch')}
						 </div>

					}
					{
						resetPwdState === 'success' &&
						<div className="success-label">
							{t('login.resetPasswordSuccess')}
						</div>
					}
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	resetViewState: getResetPasswordView(state),
	resetPwdState: state.login.resetPasswordStatus,
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword(state){
        dispatch(changePassword(state));
	},
    resetPasswordView(state) {
		dispatch(resetPasswordView(state));
	}
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PasswordReset));
