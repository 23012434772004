import { combineReducers } from 'redux';

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
} from '../actions/';

const status = {
  loading: 'LOADING',
  success: 'SUCCESS',
  error: 'ERROR',
};

const login = (state = "", action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return ""
    case USER_LOGIN_SUCCESS:
      return status.success
    case USER_LOGIN_FAILURE:
      return action.code
    default:
      return state;
  }
};


const passwordReset = (state = "", action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      return ""
    case PASSWORD_RESET_SUCCESS:
      return status.success
    case PASSWORD_RESET_FAILURE:
      return action.code
    default:
      return state;
  }
};

export default combineReducers({
  login,
  passwordReset,
});

export const getErrorLogin           = (state) => (state.apiStatus.login && state.apiStatus.login !== "") || false;
export const getSuccessPasswordReset = (state) => (state.apiStatus.passwordReset && state.apiStatus.passwordReset === status.success) || false;
export const getErrorPasswordReset   = (state) => (state.apiStatus.passwordReset && state.apiStatus.passwordReset!== "") || false;
