import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/index.sass'
import App from './App'

import * as serviceWorker from './serviceWorker'
ReactDOM.render(<App />, document.getElementById('root'))
serviceWorker.unregister()
// serviceWorker.register()
