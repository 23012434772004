export const apiDomain = process.env.REACT_APP_API_DOMAIN;
export const apiVersion = process.env.REACT_APP_API_VERSION;
export const providerHost = process.env.REACT_APP_HOST_PROVIDER;

if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

export const apiBaseUrl = window.location.origin;

/**
 * alternative: get cookie by name with using a regular expression
 */
export const getCookiebyName = function (name) {
    var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
    return !!pair ? pair[1] : null;
};

