import React from 'react'
import { CardImg } from 'reactstrap';

import logoEleneo from '../assets/images/logo_eleneo.svg';
import logoVinci from '../assets/images/logo_vinci.svg';

export const Logo = () => {
  switch (window.location.hostname.split('-')[0]) {
    case 'vinci':
      return <CardImg top width="100%" src={logoVinci} />
    default:
      return <CardImg top width="100%" src={logoEleneo} style={{
        height: '5rem',
        width: `calc(5rem * 2.9)`,
        margin: '0 auto',
        padding: '20px 0 0 0',
      }} />
  }
};
