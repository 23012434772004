import { combineReducers } from 'redux';

import apiStatus from './apiStatus';
import login from './login';

import { USER_LOGOUT_SUCCESS } from '../actions/';

const app = combineReducers({
    apiStatus,
    login: login(),
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT_SUCCESS) {
      return app(undefined, action);
  }

  return app(state, action);
};

export default rootReducer;