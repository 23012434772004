import * as user from '../api/user';
import i18n, { lngIso } from "../i18n/i18n";

import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_FAILURE,
    USER_LOGOUT_REQUEST,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_VIEW_TOGGLE,
} from './';

export const checkSession = async () => {
  try {
      const response = await user.checkSession();
      switch (response.status) {
          case 200:
              response.json().then(response => {
                  if (response.auth === true) {
                      document.location.href = response.redirect;
                  }
              });
              break;
          default:
              console.warn(`${response.status}: Error while checking session`);
              break;
      }
  } catch (error) {
      console.warn(error.message || 'Error while checking session');
  }
};

export const login = (state) => async (dispatch) => {
    let retour = false;
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        });
        const response = await user.login(state);
        switch (response.status) {
            case 200:
                const loginResult = await response.json();
                // Change language
                i18n.changeLanguage(lngIso(loginResult.LanId));
                // Redirect to requested or default application
                document.location.href = loginResult.redirect;
                retour = true;
                break;
            default:
                console.warn("Login Failed", response.status, response)
                const resp = await response.json();
                dispatch({
                    type: USER_LOGIN_FAILURE,
                    code: resp.code
                });
                break;
        }
    } catch (error) {
        console.error('Login failed', error.message, error);
        dispatch({
            type: USER_LOGIN_FAILURE,
            message: error.message || 'Error while login',
            code: 'AUTH_01',
        });
    }
    return retour;
};

export const logout = (connectedUser) => (dispatch) => {
  dispatch({
      type: USER_LOGOUT_REQUEST
  });

  return user.logout(connectedUser.id_token);
};

export const changePassword = (state) => async (dispatch) => {
    dispatch({
        type: RESET_PASSWORD_REQUEST,
    });


    try {
        const response = await user.changePassword(state)
        switch (response.status) {
            case 200:
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                });
                break;
            default:
                const status = await response.json();
                dispatch({
                    type: RESET_PASSWORD_FAILURE,
                    code: status && status.code,
                });
                break;
        }
    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_FAILURE,
            message: error.message || 'Error while changing password',
        });
    }
};

export const resetPwd = (state) => (dispatch) => {
    dispatch({
        type: PASSWORD_RESET_REQUEST,
    });

    return user.passwordReset(state).then(
        response => {
            switch (response.status) {
                case 200:
                    dispatch({
                        type: PASSWORD_RESET_SUCCESS,
                    });
                    break;
                default:
                    response.json().then(status => {
                        dispatch({
                            type: PASSWORD_RESET_FAILURE,
                            code: status && status.code,
                        });
                    });
                    break;
            }
        },
        error => {
            dispatch({
                type: PASSWORD_RESET_FAILURE,
                message: error.message || 'Something went wrong.',
            });
        }
    );
};

export const resetPasswordView = (state) => (dispatch) => {
    dispatch({
        type: RESET_PASSWORD_VIEW_TOGGLE
    });
}