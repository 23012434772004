import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import configureStore from './configureStore';
import i18n from './i18n/i18n';
import Login from './components/Login';
import PasswordReset from './components/PasswordReset';
import { checkSession } from './actions/user';

export const store = configureStore();

class App extends Component {
    componentWillMount() {
        checkSession();
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <Router>
                        <Switch>
                            <Route       path="/ResetPassword" component={PasswordReset} />
                            <Route exact path="/"                      component={Login} />
                            <Route       path="/:path"                 component={Login} />
                        </Switch>
                    </Router>
                </Provider>
            </I18nextProvider>
        );
    }
}

export default App;
