import { providerHost, getCookiebyName, apiBaseUrl } from './apiConf';

export const login = ({ usr, pwd }) => {
	let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const xsrf = getCookiebyName('E-XSRF-TOKEN');
    headers.append('X-XSRF-TOKEN', xsrf);
    const url = "account/login";

    const redirectUrl = new URL(window.location.href).searchParams.get('ReturnUrl');
    const redirectUrlStr = redirectUrl ? redirectUrl : '';
    const creds = `{
        "Username" : "${usr}",
        "Password" : "${pwd}",
        "ReturnUrl" : "${redirectUrlStr}"
    }`;

    return fetch(`${apiBaseUrl}/${url}`, {
		credentials: 'include',
		headers,
		method: 'POST',
        body: creds,
	});
};

export const logout = (accessToken) => {
    const redirectLogoutUrl = encodeURIComponent(`${apiBaseUrl}/logout`);
    const logoutUrl = `${providerHost}/connect/endsession?id_token_hint=${accessToken}&post_logout_redirect_uri=${redirectLogoutUrl}`;
	document.location.href = logoutUrl;
};

export const passwordReset = ({ email }) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const xsrf = getCookiebyName('E-XSRF-TOKEN');
    headers.append('X-XSRF-TOKEN', xsrf);

    const url = "account/resetpassword";

    const json = `{ "email": "${email}" }`;

    return fetch(`${apiBaseUrl}/${url}`, {
        credentials: 'include',
        headers,
        method: 'POST',
        body: json
    })
};

export const changePassword = ({ email, token, newPassword }) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const xsrf = getCookiebyName('E-XSRF-TOKEN');
    headers.append('X-XSRF-TOKEN', xsrf);

    const url = `account/reset_password`;

    const emailDecoded = decodeURIComponent(email)
    const tokenDecoded = decodeURIComponent(token)
    const json = `{
        "Email": "${emailDecoded}",
        "Token": "${tokenDecoded}",
        "NewPassword": "${newPassword}"
    }`;

    return fetch(`${apiBaseUrl}/${url}`, {
        credentials: 'include',
        headers,
        method: 'POST',
        body: json
    })
};

export const checkSession = () => {
    return fetch(`${apiBaseUrl}/account/checksession`, { credentials: 'include' })
};

export const renewForgery = () => {
    return fetch(`${apiBaseUrl}/account/renewforgery`, { credentials: 'include' })
};

